@tailwind base;
input, textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: theme("backgroundColor.primary.light");
  border-left: 3px solid theme("backgroundColor.primary.DEFAULT");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;
}
blockquote > * {
  margin: 0;
}

@tailwind components;
@tailwind utilities;
.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow-8 bg-card;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.list-item:hover {
  @apply bg-hover;
}

.textarea-clean {
  resize: none;
}
.textarea-clean:focus {
  outline: none;
}

.bg-pattern {
  background: linear-gradient(135deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), linear-gradient(225deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--background-base);
  background-size: 64px 128px;
}

.trans-ease-out {
  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.vex-blogpost p {
  @apply my-3;
}